import styled from "styled-components";
import { Colors, Rem } from "../../../commons/Theme";

export const StyledLateralTextContainer = styled.div`
  height: 100%;
  padding: ${Rem(10)};
  padding-right: ${Rem(30)};
  > a {
    text-decoration: none;
  }
`;

export const DiscountCode = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${Colors.white};
  width: 95%;
  height: 75px;
`;

export const ShareContainer = styled.div`
  display: flex;
  justify-content: center;
  height: 65%;
`;

export const FbShareImage = styled.img`
  width: 90%;
`;
